import { Text } from "@clipboard-health/ui-react";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { Stack } from "@mui/material";

export function PendingPlacementCandidate() {
  return (
    <Stack justifyContent="center" alignItems="center" spacing={2}>
      <PendingActionsOutlinedIcon color="warning" fontSize="large" />
      <Text variant="body2" align="center">
        Your profile is under review and will be visible to local workplaces looking to hire in your
        area shortly.
      </Text>
    </Stack>
  );
}
