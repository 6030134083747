import { Text, useModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { Button, List, ListSubheader, Stack } from "@mui/material";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";

import { CandidateRequirementListItem } from "./CandidateRequirementListItem";
import { UploadCandidateRequirementsDialog } from "./UploadCandidateRequirementsDialog";

interface UploadedCandidateRequirementsProps {
  placementCandidateId: string;
  introductionUrl?: string;
  resumeUrl?: string;
  worker: Worker;
}

export function UploadedCandidateRequirements(props: UploadedCandidateRequirementsProps) {
  const { introductionUrl, resumeUrl, worker, placementCandidateId } = props;
  const reuploadRequirementModalState = useModalState();
  return (
    <>
      <List
        subheader={
          <ListSubheader disableGutters>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Text variant="h4">Uploaded requirements</Text>
              <Button
                startIcon={<RefreshRoundedIcon />}
                variant="text"
                size="small"
                onClick={() => {
                  logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_REQUIREMENTS_REUPLOAD_CLICKED, {
                    placementCandidateId,
                  });
                  reuploadRequirementModalState.openModal();
                }}
              >
                Re-upload
              </Button>
            </Stack>
          </ListSubheader>
        }
      >
        {isDefined(introductionUrl) && (
          <CandidateRequirementListItem label="Introduction Video" url={introductionUrl} />
        )}
        {isDefined(resumeUrl) && <CandidateRequirementListItem label="Resume" url={resumeUrl} />}
      </List>
      {isDefined(introductionUrl) && reuploadRequirementModalState.modalIsOpen && (
        <UploadCandidateRequirementsDialog
          placementCandidateId={placementCandidateId}
          modalState={reuploadRequirementModalState}
          introductionUrl={introductionUrl}
          worker={worker}
        />
      )}
    </>
  );
}
