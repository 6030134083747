import { ExternalLink, Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, Stack } from "@mui/material";
import { green } from "@mui/material/colors";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { type Worker } from "@src/appV2/Worker/api/types";
import { useEffect, useState } from "react";

import { useCreateOrGetPlacementCandidate } from "../api/useCreateOrGetPlacementCandidate";

interface OnboardingPlacementCandidateProps {
  placementCandidateId: string;
  introductionUrl?: string;
  worker: Worker;
  onComplete?: () => void;
}

interface VideoAskMessageData {
  type: string;
}

export function OnboardingPlacementCandidate(props: OnboardingPlacementCandidateProps) {
  const { introductionUrl, worker, onComplete, placementCandidateId } = props;
  const { refetch: refetchPlacementCandidate } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  const [countdown, setCountdown] = useState<number | undefined>(undefined);

  useEffect(() => {
    function isVideoAskMessage(message: MessageEvent): boolean {
      const trustedOrigins = ["https://www.videoask.com"];
      if (!trustedOrigins.includes(message.origin)) {
        return false;
      }

      const data = message.data as VideoAskMessageData;
      return typeof data === "object" && "type" in data && data.type.startsWith("videoask_");
    }

    function handlePostMessage(event: MessageEvent) {
      if (isVideoAskMessage(event) && event.data.type === "videoask_submitted") {
        logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_SUBMITTED, {
          placementCandidateId,
        });
        setCountdown(10);
      }
    }

    window.addEventListener("message", handlePostMessage);

    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [placementCandidateId, worker.userId]);

  useEffect(() => {
    if (!isDefined(countdown)) {
      return undefined;
    }

    if (countdown === 0) {
      void refetchPlacementCandidate();
      onComplete?.();
      setCountdown(undefined);
      return undefined;
    }

    const timeoutId = setTimeout(() => {
      setCountdown((previous) => (isDefined(previous) ? previous - 1 : undefined));
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [countdown, refetchPlacementCandidate, onComplete]);

  return (
    <Stack spacing={2} flex={1} justifyContent="start">
      {isDefined(introductionUrl) ? null : (
        <Text variant="body2" color={green[800]}>
          Earn $50 when you complete your video introduction below.
        </Text>
      )}
      <Text variant="body2">
        After completing the video introduction below, your profile will be visible to local
        workplaces looking to hire in your area.
      </Text>
      <Text variant="body2">
        Having trouble uploading your video introduction?{" "}
        <ExternalLink
          to={`${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`}
          color="primary"
          sx={{ textDecoration: "underline" }}
          target="_blank"
          onClick={() => {
            logEvent(APP_V2_APP_EVENTS.PLACEMENT_CANDIDATE_VIDEOASK_VIA_BROWSER_CLICKED, {
              placementCandidateId,
            });
          }}
        >
          Click here to upload via browser.
        </ExternalLink>
      </Text>
      <Box sx={{ width: "100%", mb: 2, height: "calc(100vh - 180px)" }}>
        {isDefined(countdown) && (
          <Text gutterBottom align="center">
            Processing your requirements in {countdown} seconds...
          </Text>
        )}
        {/* eslint-disable-next-line react/iframe-missing-sandbox */}
        <iframe
          src={`${environmentConfig.REACT_APP_VIDEOASK_UPLOAD_URL}#worker_id=${worker.userId}&contact_name=${worker.name}&contact_email=${worker.email}`}
          title="VideoAsk Introduction"
          width="100%"
          height="100%"
          allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          style={{
            border: "none",
            borderRadius: "8px",
          }}
        />
      </Box>
    </Stack>
  );
}
