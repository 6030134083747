import { z } from "zod";

import { type Address, type Location } from "../lib/GoogleMaps";

export enum PlacementCandidateStatus {
  DISABLED = "disabled",
  ONBOARDING = "onboarding",
  PENDING = "pending",
  ACTIVATED = "activated",
}

export enum JobType {
  FULL_TIME = "full_time",
  PART_TIME = "part_time",
  PER_DIEM = "per_diem",
}

export enum ShiftNameType {
  AM = "am",
  PM = "pm",
  NOC = "noc",
}

export const placementGeoLocation = z.object({
  coordinates: z.tuple([z.number(), z.number()]),
  type: z.literal("Point"),
});

export type PlacementGeoLocation = z.infer<typeof placementGeoLocation>;

export interface PreferredCandidateArea {
  location: PlacementGeoLocation;
  address: Address;
  distance: number;
}

export interface PreferredCandidateAreaForm {
  address: Address;
  location: Location;
  distance: number;
}

export interface PlacementCandidate {
  id: string;
  status: PlacementCandidateStatus;
  introductionUrl?: string;
  resumeUrl?: string;
  jobTypesInterested?: JobType[];
  shiftTypesInterested?: ShiftNameType[];
  preferredAreas?: PreferredCandidateArea[];
  worker: {
    id: string;
    name: string;
    qualification: string;
    distance?: number;
    completedShiftsCount?: number;
    cbhPassportDocuments?: string[];
    email?: string;
    phone?: string;
    profileImageUrl?: string;
  };
}

export const placementCandidateWorkerAttributeIncludedSchema = z.object({
  id: z.string(),
  type: z.literal("workers"),
  attributes: z.object({
    name: z.string(),
    qualification: z.string(),
    distance: z.number().optional(),
    completedShiftsCount: z.number().optional(),
    cbhPassportDocuments: z.array(z.string()).optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    profileImageUrl: z.string().optional(),
  }),
});

export const placementCandidateSchema = z.object({
  id: z.string(),
  type: z.literal("placement-candidates"),
  attributes: z.object({
    status: z.nativeEnum(PlacementCandidateStatus),
    jobTypesInterested: z.array(z.nativeEnum(JobType)).optional(),
    shiftTypesInterested: z.array(z.nativeEnum(ShiftNameType)).optional(),
    preferredAreas: z
      .array(
        z.object({
          location: z.object({
            type: z.literal("Point"),
            coordinates: z.tuple([z.number(), z.number()]),
          }),
          address: z.object({
            city: z.string().optional(),
            country: z.string().optional(),
            countryCode: z.string().optional(),
            formatted: z.string().optional(),
            line1: z.string().optional(),
            postalCode: z.string().optional(),
            region: z.string().optional(),
            subpremise: z.string().optional(),
            state: z.string().optional(),
            stateCode: z.string().optional(),
            streetName: z.string().optional(),
            streetNumber: z.string().optional(),
            metropolitanStatisticalArea: z.string().optional(),
          }),
          distance: z.number(),
        })
      )
      .optional(),
    introductionUrl: z.string().optional(),
    resumeUrl: z.string().optional(),
  }),
  relationships: z.object({
    worker: z.object({
      data: z.object({
        id: z.string(),
        type: z.literal("workers"),
      }),
    }),
  }),
});
